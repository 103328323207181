<template>
  <v-container :class="'grey lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col pb-0 mb-0">
        <v-card outlined>
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
          >
            Контакты
          </v-card-title>
          <v-card-text class="text-body-1 text-center">
            117246, город Москва, Научный проезд, дом 17<br />
            Бизнес-Центр "9 Акров"<br />
            <p><a v-on:click="mapHidden = !mapHidden">Показать на карте</a></p>
            <yandex-map
              v-if="!mapHidden"
              zoom="15"
              style="width: 100%; max-width: 100%; height: 60vh"
              :coords="coords"
            >
              <ymap-marker
                marker-id="1"
                :coords="coords"
                :hint-content="hintContent"
                :balloon-template="hintContent"
              ></ymap-marker>
            </yandex-map>
          </v-card-text>
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-5 flex-row"
          >
            Реквизиты
          </v-card-title>
          <v-card-text>
            <div class="mobileTable" v-if="isMobile">
              <h3>Полное наименование организации</h3>
              <div>Акционерное общество «МЕДИА ХИЛС»</div>
              <h3>Краткое наименование организации</h3>
              <div>Акционерное общество «МЕДИА ХИЛС»</div>
              <h3>Юридический адрес</h3>
              <div>
                117105, Москва, Варшавское шоссе, д.&nbsp;1 А, этаж 3,
                комната&nbsp;50, офис А3Б
              </div>
              <h3>Почтовый адрес</h3>
              <div>
                115419, Москва, ул.&nbsp;Шаболовка, д.&nbsp;34, стр.&nbsp;5
                АО&nbsp;«МЕДИА&nbsp;ХИЛС»
              </div>
              <h3>Фактический адрес</h3>
              <div>117246, Москва, Научный проезд д.&nbsp;17</div>
              <h3>ИНН</h3>
              <div>7726385360</div>
              <h3>КПП</h3>
              <div>772601001</div>
              <h3>ОГРН</h3>
              <div>1167746810440</div>
              <h3>Банк</h3>
              <div>АО &laquo;Райффайзенбанк&raquo;, г.&nbsp;Москва</div>
              <h3>БИК</h3>
              <div>044525700</div>
              <h3>Корр. счет</h3>
              <div>301 018 102 000 000 007 00</div>
              <h3>Расчетный счет</h3>
              <div>407 028 100 000 000 253 14</div>
              <h3>Генеральный директор</h3>
              <div>
                Бояринов Андрей Петрович<br />действует на&nbsp;основании Устава
              </div>
              <h3>Email</h3>
              <div>
                <a href="mailto:info@MediaHills.ru">info@MediaHills.ru</a>
              </div>
              <h3>Web-сайт</h3>
              <div>
                <a href="http://www.mediaHills.ru">www.MediaHills.ru</a>
              </div>
              <h3>Телефон</h3>
              <div>
                <a href="tel:+74997052933"><nobr>+7 (499) 705-29-33</nobr></a>
              </div>
            </div>
            <v-data-table hide-default-footer v-if="!isMobile">
              <template v-slot:body>
                <tbody>
                  <tr>
                    <td>Полное&nbsp;наименование&nbsp;организации</td>
                    <td>Акционерное общество «МЕДИА ХИЛС»</td>
                  </tr>
                  <tr>
                    <td>Краткое наименование организации</td>
                    <td>АО «МЕДИА ХИЛС»</td>
                  </tr>
                  <tr>
                    <td>Юридический адрес</td>
                    <td>
                      117105, Москва, Варшавское шоссе, д.&nbsp;1 А, этаж 3,
                      комната&nbsp;50, офис А3Б
                    </td>
                  </tr>
                  <tr>
                    <td>Почтовый адрес</td>
                    <td>
                      115419, Москва, ул.&nbsp;Шаболовка, д.&nbsp;34,
                      стр.&nbsp;5 АО&nbsp;«МЕДИА&nbsp;ХИЛС»
                    </td>
                  </tr>
                  <tr>
                    <td>Фактический адрес</td>
                    <td>117246, Москва, Научный проезд д.&nbsp;17</td>
                  </tr>
                  <tr>
                    <td>ИНН</td>
                    <td>7726385360</td>
                  </tr>
                  <tr>
                    <td>КПП</td>
                    <td>772601001</td>
                  </tr>
                  <tr>
                    <td>ОГРН</td>
                    <td>1167746810440</td>
                  </tr>
                  <tr>
                    <td>Банк</td>
                    <td>АО &laquo;Райффайзенбанк&raquo;, г.&nbsp;Москва</td>
                  </tr>
                  <tr>
                    <td>БИК</td>
                    <td>044525700</td>
                  </tr>
                  <tr>
                    <td>Корр. счет</td>
                    <td>301 018 102 000 000 007 00</td>
                  </tr>
                  <tr>
                    <td>Расчетный счет</td>
                    <td>407 028 100 000 000 253 14</td>
                  </tr>
                  <tr>
                    <td>Генеральный директор</td>
                    <td>
                      Бояринов Андрей Петрович<br />действует на&nbsp;основании
                      Устава
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <a href="mailto:info@MediaHills.ru">info@MediaHills.ru</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Web-сайт</td>
                    <td>
                      <a href="http://www.mediaHills.ru">www.MediaHills.ru</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Телефон</td>
                    <td>
                      <a href="tel:+74997052933"
                        ><nobr>+7 (499) 705-29-33</nobr></a
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=Help&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
  </v-container>
</template>
<style scoped>
.mobileTable {
  padding-left: 8px;
  margin-top: -20px;
  margin-bottom: 20px;
}
.mobileTable h3 {
  margin: 14px 0 6px 0;
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
}
.mobileTable div {
  color: rgba(0, 0, 0, 0.6);
}
</style>
<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  components: { yandexMap, ymapMarker },
  name: "Help",
  data: () => ({
    mapHidden: true,
    coords: [55.654137, 37.555876],
    hintContent: "Москва, Научный проезд, дом 17",
  }),
  mounted() {
    this.$router.push({ name: "help" });
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
